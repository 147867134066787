.hero {
    background-color: white;
}

footer {
    background-color: white;
}

.fancy {
    font-family: 'Lilita One', cursive;
    width: fit-content;
    background: linear-gradient(323deg, rgba(160, 0, 255, 1) 6%, rgba(0, 7, 255, 1) 21%, rgba(11, 255, 0, 1) 44%, rgba(239, 255, 0, 1) 61%, rgba(255, 130, 0, 1) 84%, rgba(255, 0, 0, 1) 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: gradient 10s linear infinite;
    animation: gradient 10s linear infinite;
    background-size: 400% 400%;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@-webkit-keyframes gradient {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

html,
body,
#root {
    height: 100%;
}

.App {
    background: linear-gradient(323deg, rgba(160, 0, 255, 1) 6%, rgba(0, 7, 255, 1) 21%, rgba(11, 255, 0, 1) 44%, rgba(239, 255, 0, 1) 61%, rgba(255, 130, 0, 1) 84%, rgba(255, 0, 0, 1) 100%);
}

.App .container {
    padding-top: 3%;
}

.post ul {
    list-style-type: disc;
    padding-left: 3%;
}